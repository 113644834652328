
import './HomeContentText.css'
export default function HomeContentText() {

    return (
        <div className="HomeContentText-Container">
            <h1>About Us</h1>
            <p>Its literally happening ipsum dolor sit amet consectetur adipisicing elit. Quo quas quam quidem dicta ratione, sint ducimus maiores ipsam! Rerum nemo laborum exercitationem, quas error ad qui odit sed placeat illo.</p>
            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Quo quas quam quidem dicta ratione, sint ducimus maiores ipsam! Rerum nemo laborum exercitationem, quas error ad qui odit sed placeat illo.</p>
            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Quo quas quam quidem dicta ratione, sint ducimus maiores ipsam! Rerum nemo laborum exercitationem, quas error ad qui odit sed placeat illo.</p>
            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Quo quas quam quidem dicta ratione, sint ducimus maiores ipsam! Rerum nemo laborum exercitationem, quas error ad qui odit sed placeat illo.</p>
            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Quo quas quam quidem dicta ratione, sint ducimus maiores ipsam! Rerum nemo laborum exercitationem, quas error ad qui odit sed placeat illo.</p>
            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Quo quas quam quidem dicta ratione, sint ducimus maiores ipsam! Rerum nemo laborum exercitationem, quas error ad qui odit sed placeat illo.</p>

        </div>

    );
}