import './LostContentText.css'

export default function LostContentText() {

    return (
        <div className="LostContent-Text">
            <h1> Lost Cats Info</h1>
            <p> To your right you will find Lost cats looking to be foundblas basl abldsakdfj blas blah blah blah</p>

        </div>
    )
}